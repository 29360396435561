import React from 'react';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { accountProfileSelector } from 'state/account/account.selectors';
import { useSelector } from 'react-redux';

import ProfileLayout from 'components/layouts/profile/profile.layout';
import { Container } from 'react-bootstrap';

const Insurance = () => {
    const { t } = useTranslation();
    const profileObject = useSelector(accountProfileSelector);

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.eyebrowText`)}
            title={t(`pages.profile.insurance.title`)}
            heading={t(`pages.profile.insurance.heading`)}
        >
            <Container>
                <p className="profile-form-instructions">{t(`pages.profile.insurance.memberID`)}</p>
                <Container>
                    <p>{profileObject?.cardholderID}</p>
                </Container>
            </Container>
        </ProfileLayout>
    );
};

export default Insurance;
